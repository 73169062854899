import CryptoJS from "crypto-js";

export const formattedDate = (date) => {
  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const d = new Date();
  const today = new Date(date);
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  // const formattedToday = dd + "-" + mm + "-" + yyyy;
  const formattedToday = dd + "-" + monthNames[today.getMonth()] + "-" + yyyy;
  return formattedToday;
};

function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
export const getLoggedData = () => {
  let result = {}
  const secretPass = "XkhZG4fW2t2W0#$";
  const dataUser = localStorage.getItem('data');
  if (dataUser) {
    const bytes = CryptoJS.AES.decrypt(dataUser, secretPass);
    let d = isJsonString(bytes.toString(CryptoJS.enc.Utf8))
    if (d) {
      result = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
  }
  return result;
}

export const bytesToGigabytes = (bytes) => {
  const BYTES_IN_GIGABYTE = 1073741824; // 1024^3
  // return bytes / BYTES_IN_GIGABYTE;
  // bytes = parseInt(bytes, 10);
  return (bytes / BYTES_IN_GIGABYTE).toFixed(2);
};


// Function to aggregate data stream-wise and count occurrences
export const aggregateAndCountStreams = (data) => {
  const result = Object.values(data.reduce((acc, item) => {
      if (!acc[item.stream]) {
          acc[item.stream] = {
              stream: item.stream,
              count: 0,
              bytes_recv: 0,
              bytes_sent: 0
          };
      }
      acc[item.stream].count += 1;
      acc[item.stream].bytes_recv += item.bytes_recv;
      acc[item.stream].bytes_sent += item.bytes_sent;
      return acc;
  }, {}));

  return result;
};


export const filterAgentName = (value) =>{
  const userAgent = value
  let result = ''
if (userAgent.includes("Android 9")) {
  console.log("Found: Android 9");
  result = 'Android 9'
} else if (userAgent.includes("Android 10")) {
  console.log("Found: Android 10");
  result = 'Android 10'
} else if(userAgent.includes("Android 11")){
 result = 'Android 11'
} else if(userAgent.includes("Android 8.1.0")){
  result = 'Android 8.1.0'
 } else if(userAgent.includes("Android 7.1.2")){
  result = 'Android 7.1.2'
 } else if(userAgent.includes("Android 7.0.2")){
  result = 'Android 7.0.2'
 } else if(userAgent.includes("Android 7.0.3")){
  result = 'Android 7.0.3'
 } else if(userAgent.includes("Android 7.1.1")){
  result = 'Android 7.1.1'
 } else if(userAgent.includes("Android 11")){
  result = 'Android 11'
 } else if(userAgent.includes("Android 11")){
  result = 'Android 11'
 } else {
  console.log("Android 9 or Android 10 not found");
  result = value
}
return result
}

export const getAgentName =(value)=>{
// Original string
// Split the string using the regular expression for '(' and ')'
// if(value=="Nimble/3.7.13-3"){
if(value.includes("Nimble")){
  return "SUB-CDN SERVER"
}else{
  let parts = value.split(/[()]/);

  // Remove any empty strings that might result from the split
  parts = parts.filter(part => part.trim() !== '');
  let result = parts[1].split(';');
  console.log(parts);
  
    return result[1]
}

}

export const bytesToMB =(bytes) => {
  const MB = bytes / (1024 * 1024);
  // return MB.toFixed(2);
  return MB.toFixed(2); // Return the result with 2 decimal places
}