import {API, BASE_URL} from './config';

export default {
    getAllUsers: async ()=>{
        try {
            const response = await API.get(`${BASE_URL}/auth/user-list`);
            return response;
        } catch (error) {
            // console.log('error', error)
            return error.response;
        }
    },

    userStatus: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/user/update-user-status`,reqBody);
            return response;
        } catch (error) {
            // console.log('User Status', error);
            return error.response;
        }
    },
    ChnagePassword: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/user/update-password`, reqBody);
            return response;
        } catch (error) {
            // console.log('Update User', error);
            return error.response;
        }
    },
    deleteUser: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/user/delete-profile-by-admin`, reqBody);
            return response;    
        } catch (error) {
            // console.log('Detele User', error);
            return error.response;
        }
    },
    createUser: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/auth/register`, reqBody);
            return response;    
        } catch (error) {
            // console.log('Add User', error);
            return error.response;
        }
    },
    updateUser: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/user/update-profile`, reqBody);
            return response;    
        } catch (error) {
            // console.log('Update User', error);
            return error.response;
        }
    },

    getServersList: async ()=>{
        try {
            const response = await API.get(`${BASE_URL}/server/list`);
            return response;
        } catch (error) {
            // console.log('error', error)
            return error.response;
        }
    },

    addServer: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/server/add-ip`, reqBody);
            return response;    
        } catch (error) {
            // console.log('Add Server', error);
            return error.response;
        }
    },

    getServerSession: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/server/sessions`, reqBody);
            return response;    
        } catch (error) {
            // console.log('Session Server', error);
            return error.response;
        }
    },

    getBasicServerStatus: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/server/basic-server-status`, reqBody);
            return response;    
        } catch (error) {
            // console.log('Session Server', error);
            return error.response;
        }
    },

    updateServer: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/server/update`, reqBody);
            return response;    
        } catch (error) {
            // console.log('Update User', error);
            return error.response;
        }
    },

    serverListByUser: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/server/list-by-user`, reqBody);
            return response;    
        } catch (error) {
            // console.log('err', error);
            return error.response;
        }
    },

    serverById: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/server/server-by-id`, reqBody);
            return response;    
        } catch (error) {
            // console.log('err', error);
            return error.response;
        }
    },

}