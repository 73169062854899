import React, { useEffect, useState } from "react";
import userServices from "../services/user.services";
import { Modal } from "reactstrap";
import {
  aggregateAndCountStreams,
  bytesToGigabytes,
  bytesToMB,
  filterAgentName,
  getAgentName,
  getLoggedData,
} from "../helpers/Helper";
import { useLocation, useParams } from "react-router-dom";
import ActiveSessionsPopUp from "../components/Servers/ActiveSessionsPopUp";

const ServerInfo = () => {
  let id = new URLSearchParams(window.location.search).get("id");
  const location = useLocation();
  const [modal, setModal] = React.useState(false);
  const toggle = () => setModal(!modal);
  const [modalServerList, setModalServerList] = React.useState(false);
  const toggleServerList = () => setModalServerList(!modalServerList);
  const [isAddMode, setIsAddMode] = useState("Add");
  const [servers, setServers] = useState([]);
  const [serverData, setServerdata] = useState("");
  const [serverId, setServerId] = useState("");
  const [basicServerStatus, setBasicServerStatus] = useState([]);
  const [channelSummary, setChannelSummary] = useState([]);
  const [totalByteSent, setTotalByeSent] = useState(0);
  const [totalByteRec, setTotalByteRec] = useState(0);
  const [sessionServerList, setSessionServerList] = useState([]);
  const [selectedRow, setSelectedRow] = useState("");

  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryInSummary, setSearchQueryInSummary] = useState("");

  // let { id } = useParams();

  const loggedData = getLoggedData();
  const getServers = async () => {
    if (loggedData.user.user_type === 1) {
      let result = await userServices.getServersList();
      result = result.data.data;
      setServers(result);
    } else {
      let result = await userServices.serverListByUser({
        id: loggedData.user.id,
      });
      result = result.data.data;
      setServers(result);
    }
  };

  const getServerDetail = async (id) => {
    try {
      const res = await userServices.serverById({ id: Number(id) });
      if (res.status === 200) {
        setBasicServerStatus("");
        setTotalByeSent(0);
        setTotalByteRec(0);
        setChannelSummary([]);
        setSessionServerList([]);

        let resData = res.data.data;
        setServerdata(res.data.data);
        if (res.data.data.id) {
          const reqBody = {
            ip: resData.ip,
            api_auth: resData?.api_auth || "",
          };
          let result = await userServices.getBasicServerStatus(reqBody);

          if (result.status === 200) {
            setBasicServerStatus(result.data.data);
            let resultServerSession = await userServices.getServerSession(
              reqBody
            );

            if (resultServerSession.status === 200) {
              if (resultServerSession.data.data.length > 0) {
                let dataList = resultServerSession.data.data;
                setChannelSummary(
                  aggregateAndCountStreams(resultServerSession.data.data)
                );
                setSessionServerList(dataList);
                let totalBytesRec = dataList.reduce(
                  (n, { bytes_recv }) => n + bytes_recv,
                  0
                );
                let totalByteSent = dataList.reduce(
                  (n, { bytes_sent }) => n + bytes_sent,
                  0
                );
                setTotalByeSent(totalByteSent);
                setTotalByteRec(totalBytesRec);
              }
            }
          }
        }
      }
    } catch (error) {
      console.log("err");
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const filteredSessions = sessionServerList.filter((session) => {
    return (
      session.id.toString().includes(searchQuery) ||
      session.stream.toLowerCase().includes(searchQuery.toLowerCase()) ||
      session.bytes_recv.toString().includes(searchQuery) ||
      session.bytes_sent.toString().includes(searchQuery) ||
      session.user_agent.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  const handleSearchChangeSummary = (event) => {
    setSearchQueryInSummary(event.target.value);
  };
  const filteredSummary = channelSummary.filter((session) => {
    return session.stream
      .toLowerCase()
      .includes(searchQueryInSummary.toLowerCase());
  });
  useEffect(() => {
    let path = window.location.pathname;
    const parts = path.split("/");
    setServerId(id);
    getServers();
    getServerDetail(id);
  }, [id]);
  // }, [location.pathname], [serverId]);
  return (
    <div className="page-inner">
      {/* Main Wrapper */}
      <div id="main-wrapper">
        <div className="pageheader pd-t-25 pd-b-35">
          <div className="pd-t-5 pd-b-5">
            <h1 className="pd-0 mg-0 text-overflow">
              {serverData.server_name}
            </h1>
          </div>
        </div>

        <div className="row row-xs clearfix">
          <div className="col-md-12 col-lg-12">
            <div className="card mg-b-20">
              <div className="card-header justify-content-center">
                {/* <h4 className="card-header-title text-center">Server Info</h4> */}
                <h5 className="text-center">Server Info</h5>
              </div>

              <div className="card-body" id="collapse1">
                <div className="row row-cols-lg-5 row-cols-md-3 row-cols-2">
                  <div className="col">
                    <div className="card text-center py-3">
                      <h3>{basicServerStatus[0]?.Connections || 0}</h3>
                      <p>Total Connections</p>
                    </div>
                  </div>

                  <div className="col">
                    <div className="card text-center py-3">
                      <h3>{basicServerStatus[0]?.SysInfo?.ap}</h3>
                      <p>CPU CORES</p>
                    </div>
                  </div>

                  <div className="col">
                    <div className="card text-center py-3">
                      <h3>{basicServerStatus[0]?.SysInfo?.scl} </h3>
                      <p>CPU USAGE</p>
                    </div>
                  </div>

                  <div className="col">
                    <div className="card text-center py-3">
                      <h3>
                        {bytesToGigabytes(
                          basicServerStatus[0]?.SysInfo?.tpms || 0
                        )}{" "}
                        GB
                      </h3>
                      <p>TOTAL PHYSICAL MEMORY</p>
                    </div>
                  </div>

                  <div className="col">
                    <div className="card text-center py-3">
                      <h3>
                        {bytesToGigabytes(
                          basicServerStatus[0]?.SysInfo?.fpms || 0
                        )}{" "}
                        GB
                      </h3>
                      <p>FREE PHYSICAL MEMORY</p>
                    </div>
                  </div>
                </div>
                <div className="row row-cols-lg-5 row-cols-md-3 row-cols mt-3">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header justify-content-center align-items-center gap-2">
                        <h5 className="text-left mb-0 w-75">Summary</h5>
                        <input
                          type="text"
                          placeholder="Search..."
                          value={searchQueryInSummary}
                          onChange={handleSearchChangeSummary}
                          className="form-control w-25"
                        />
                      </div>
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">Channel Name</th>
                            <th scope="col">Total Connections</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredSummary.map((item, index) => (
                            <tr key={index}>
                              <td
                                onClick={() => {
                                  console.log("itemOnClick", item);
                                  setSelectedRow(item.stream);
                                  toggle();
                                }}
                                style={{ cursor: 'pointer' }}
                              >
                                {item.stream}
                              </td>
                              <td>{item.count}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                {/* <div className="row row-cols-lg-5 row-cols-md-3 row-cols mt-3">
                  <div className="col-12">
                    <div className="card">
                          <div className="card-header justify-content-center">
                            <h5 className="text-center">Network Summary</h5>
                          </div>
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">Total Bytes Rec</th>
                            <th scope="col">Total Bytes Sent</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{totalByteRec}</td>
                            <td>{totalByteSent}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div> */}

                <div className="row row-cols-lg-5 row-cols-md-3 row-cols mt-3">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header justify-content-end flex-nowrap gap-3">
                        <h5 className="text-left mb-0 w-75">Active Session</h5>
                        <input
                          type="text"
                          placeholder="Search..."
                          value={searchQuery}
                          onChange={handleSearchChange}
                          className="form-control w-25"
                        />
                      </div>
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">Session Id</th>
                            <th scope="col">Channel Name</th>
                            {/* <th scope="col">Client IP</th> */}
                            <th scope="col">Bytes Rec</th>
                            <th scope="col">Bytes Sent</th>
                            <th scope="col">User Agent</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredSessions.map((item, index) => (
                            <tr key={index}>
                              <td>{item.id}</td>
                              <td>{item.stream}</td>
                              {/* <td>{item.client_ip}</td> */}
                              <td>{bytesToMB(item.bytes_recv)} MB</td>
                              <td>{bytesToMB(item.bytes_sent)} MB</td>
                              {/* <td>{filterAgentName(item.user_agent)}</td> */}
                              <td>{getAgentName(item.user_agent)}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*/ Main Wrapper End */}

      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ActiveSessionsPopUp
          toggle={toggle}
          isAddMode={isAddMode}
          sessionServerList={sessionServerList}
          selectedRow={selectedRow}
        />
      </Modal>
    </div>
  );
};

export default ServerInfo;
