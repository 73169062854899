import axios from "axios";

const API = axios;
// const BASE_URL='http://localhost:4000';
// const BASE_URL='http://192.168.1.122:4013'
// const BASE_URL='http://122.168.122.27:4013'
const BASE_URL='http://103.189.178.74:4014';

export  {
    API,
    BASE_URL
}