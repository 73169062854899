import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getLoggedData } from "../../helpers/Helper";
import userServices from "../../services/user.services";

const SideBar = ({ menuIconOnClick, handleMouseOver, handleMouseOut }) => {
  const [isActive, setIsActive] = useState("");
  const [servers, setServers] = useState([])
  const [activeParentMenu, setActiveParentMenu] = useState('')
  const [pathId, setPathId] = useState('')
  const onClick = (value) => {
  
    setIsActive(value);
  };

  const onClickParentMenu = (value)=>{
    // setActiveParentMenu(value)
    setActiveParentMenu(prevActiveParentMenu => prevActiveParentMenu === value ? '': value);
  }

const getServers = async () => {
  if(loggedData.user.user_type===1){
      let result = await userServices.getServersList();
      result = result.data.data;
      setServers(result);
  }else{
      let result = await userServices.serverListByUser({id: loggedData.user.id});
      result = result.data.data;
      setServers(result);
  }
}
const loggedData = getLoggedData()
  useEffect(() => {
    getServers()
    setIsActive(window.location.pathname);
    const addClassBody = document.body.classList.add("dashboard-BG");
  }, [isActive]);

  useEffect(()=>{
    let pathId = (new URLSearchParams(window.location.search)).get("id")
    setPathId(pathId)
  })
  return (


    <div className="page-sidebar">
      <div className="logo">
        <a className="logo-img">
          <img
            className="desktop-logo"
            src="assets/images/logo-white.png"
            alt=""
          />
          <img
            className="small-logo"
            src="assets/images/small-logo.png"
            alt=""
          />
        </a>
        <i className="ion-ios-close-empty" id="sidebar-toggle-button-close" onClick={menuIconOnClick}/>
      </div>
      {/*================================*/}
      {/* Sidebar Menu Start */}
      {/*================================*/}
      <div className="page-sidebar-inner">
        <div className="page-sidebar-menu">
          <ul className="accordion-menu">
            {/* <li className="active">
          <a href="widgets.html">
            <i data-feather="layout" />
            <span>Users</span>
          </a>
        </li> */}

         {loggedData.user?.user_type === 1 && 
         <>
         <li
              className={isActive === "/" || isActive === "/" ? "active" : ""}
            >
              <Link to="/" onClick={onClick}>
                <i className='fa fa-users'></i>
                <span>Users</span>
              </Link>
            </li>
            <li
              className={isActive === "/servers" || isActive === "/servers" ? "active" : ""}
            >
              <Link to="/servers" onClick={onClick}>
                <i className='fa fa-code-fork'></i>
                <span>Servers</span>
              </Link>
             

            </li>
            </>
          }
 {loggedData.user?.user_type !=1 && 
  <>                              <li
  className={isActive === `/home` ? "active" : ""}
>
<Link to="/home" onClick={() => { onClick(); onClickParentMenu('home'); }}>
  <i className='fa fa-code-fork'></i>
  <span>Home</span>
</Link>

    </li>
                <li className={activeParentMenu===`p_servers` ? 'open active': ''}>
                        <a href onClick={()=>onClickParentMenu('p_servers')} style={{ cursor: 'pointer' }}>
                            <i data-feather="home" />
                            <span>Servers</span>
                            <i className="accordion-icon fa fa-angle-left" />
                        </a>
                        <ul className="sub-menu" style={{ display: activeParentMenu===`p_servers`?"block":"none" }}>
                            { servers.map((item) => (
                              <>
                              <li
                              className={`${isActive}?id=${pathId}` === `/server_info?id=${item.id}` ? "active" : ""}
                            >
                                <Link to={`/server_info?id=${item.id}`} onClick={onClick} key={item.id}>
                                  <i className='fa fa-code-fork'></i>
                                  <span>{item.server_name}</span>
                                </Link>
                                </li>
                                </>
                              ))}
                           
                        
                        </ul>
                    </li>
                  </>
                  }
          </ul>
        </div>
      </div>
      {/*/ Sidebar Menu End */}
      {/*================================*/}
    </div>
  );
};

export default SideBar;
