import React, {  useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import authServices from '../../src/services/auth.services';
import { useFormik } from "formik";
import * as yup from "yup";
import { AuthSchema } from '../../src/schema/AuthSchema';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CryptoJS from "crypto-js";
import { getLoggedData } from '../helpers/Helper';

const Login =()=>{

  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const secretPass = "XkhZG4fW2t2W0#$";

  const formik = useFormik({
      initialValues: {
          email: '',
          password: ''
      },
      validationSchema: AuthSchema('login'),
      onSubmit: async (values) => {
          const reqBody = {
              "email": values.email,
              "password": values.password,
          }
          // alert(JSON.stringify(reqBody, null, 2));
          const result = await authServices.loginApi(reqBody);

          if (result.status === 200) {
              
                  result.data.auth = true;
                  const endata = CryptoJS.AES.encrypt(
                      JSON.stringify(result.data),
                      secretPass
                  ).toString();
                  localStorage.setItem('data', endata);
                  if(result.data.user?.user_type === 1){
                    navigate("/");
                  }else{
                    navigate('/home')
                  }
              
          } else {
              toast.error('Invalid  Credentials', { toastId: 'login_fail', autoClose: 3000 });
          }
      },
  });

  useEffect(() => {
      let getUserData
      if(localStorage.getItem('data')){
           getUserData = getLoggedData();
      }
      if (getUserData?.auth === true) {
          navigate("/");
      }
  });
    return(
        <>
  {/* User Singin Start */}
  {/*================================*/}
  <div className="ht-100v d-flex">
    <div className="card shadow-none pd-20 mx-auto wd-300 text-center bd-1 align-self-center">
      <h4 className="card-title mt-3 text-center">Sing In</h4>
      <p className="text-center">Sing in to your account</p>
      {/* <p>
        <a href="" className="btn btn-block btn-twitter tx-13 hover-white">
          {" "}
          <i className="fa fa-twitter" /> Login via Twitter
        </a>
        <a href="" className="btn btn-block btn-facebook tx-13 hover-white">
          {" "}
          <i className="fa fa-facebook-f" /> Login via facebook
        </a>
      </p> 
      <p className="divider-text">
        <span className="bg-light">OR</span>
      </p>
      */}
      <form onSubmit={formik.handleSubmit}>
        <div className="form-group input-group mb-0">
          <div className="input-group-prepend">
            <span className="input-group-text pd-x-9">
              {" "}
              <i className="fa fa-envelope" />{" "}
            </span>
          </div>
          <input
            className="form-control form-control-sm"
            placeholder="Email address"
            type="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            id="email"
          />
        </div>
           {formik.touched.email && formik.errors.email ? (
              <small className="text-danger d-block text-left">{formik.errors.email}</small>
            ) : null}
        <div className="form-group input-group mb-0 mt-lg-3 mt-2">
          <div className="input-group-prepend">
            <span className="input-group-text">
              {" "}
              <i className="fa fa-lock" />{" "}
            </span>
          </div>
          <input
            type={showPassword === true ? "text" : "password"}
            className="form-control form-control-sm"
            placeholder="Create password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            id="password"
            value={formik.values.password}
          />
          <a
                                                                    href="#"
                                                                    className="input-group-text "
                                                                    onClick={() => setShowPassword((prevState) => !prevState === true)}
                                                                >
                                                                    {showPassword === true ? <i className="fa fa-eye" /> : <i className="fa fa-eye-slash" />}

                                                                </a>
        </div>
        {formik.touched.password && formik.errors.password ? (
                                                            <small className="text-danger d-block text-left">{formik.errors.password}</small>
                                                        ) : null}
        {/* <p className="text-center">
          <a href="page-password.html">Forget Password?</a>
        </p> */}
        <div className="form-group">
          <button
            type="submit"
            className="btn btn-custom-primary btn-block tx-13 hover-white mt-3"
          >
            {" "}
            Login{" "}
          </button>
        </div>
        {/* <p className="text-center">
          Don't have an account?
          <br /> <a href="page-singup.html">Create Account</a>{" "}
        </p> */}
      </form>
    </div>
  </div>
  {/*/ User Singin End */}
</>

    )
}

export default Login