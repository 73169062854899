
import React, { useState } from 'react';
import { ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import ServerStatusList from './ServerStatusList';
import BasicServerStatus from './BasicServerStatus';

const ServerDetailsList = ({toggle, serverDetailListType, selectedRow}) => {
const [rowData, setRowData]=useState(selectedRow)
    return (
        <React.Fragment>
     
                    <ModalHeader toggle={toggle}>
                        <div className="card-title">
                            <h5 className='text-dark'>{serverDetailListType==='BASIC_SERVER_LIST' && 'Basic Server Detail'}{serverDetailListType==='ACTIVE_SESSIONS' && 'Session Server Detail'}</h5>
                        </div>    
                    </ModalHeader>
                    <ModalBody className="modal-body">
                        {serverDetailListType==='ACTIVE_SESSIONS' && <ServerStatusList selectedRow={rowData}/>}  
                                {/* <ServerStatusList /> */}
                                {serverDetailListType==='BASIC_SERVER_LIST' && <BasicServerStatus selectedRow={rowData}/>}
                    </ModalBody>
                    <ModalFooter>
                        <button
                            type="button"
                            className="btn btn-secondary btn-sm"
                            onClick={toggle}
                        >
                            Close
                        </button>
                    </ModalFooter>
              
        </React.Fragment>
    );
};

export default ServerDetailsList;