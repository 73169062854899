import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import { ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { toast } from "react-toastify";
import { FieldSchema } from "../../schema/FieldSchema";
import TextError from "../../helpers/FormikHelper/TextError";
import userServices from "../../services/user.services";
import MultiSelectField from "../Comman/MultiSelectField";
import MultiSelect from "../Comman/MultiSelectField";

const UserForm = ({ toggle, isAddMode, selectedRow, getUsers, servers }) => {
  const initialValues = {
    name: "",
    email: "",
    password: "",
    servers_id: [],
  };

  const savedValues = {
    name: selectedRow?.name,
    email: selectedRow?.email,
    servers_id: selectedRow?.servers_id
      ? selectedRow?.servers_id.split(",")
      : [],
    password: ''  
  };

  const onSubmit = async (values) => {
    const commaSeparatedString = values.servers_id.join(",");
    const reqBody = {
      name: values.name,
      email: values.email,
      password: values.password,
      servers_id: commaSeparatedString,
    };
    if (isAddMode === "Add") {
      let result = await userServices.createUser(reqBody);
      if (result.data.status === true) {
        toggle();
        getUsers();
        toast.success("Add User successfully", {
          toastId: "registration_success",
          autoClose: 3000,
        });
      } else {
        toast.error(result.data.message, {
          toastId: "registration_fail",
          autoClose: 3000,
        });
      }
    } else {
      const commaSeparatedString = values.servers_id.join(",");
      const reqBody = {
        name: values.name,
        email: values.email,
        user_id: selectedRow?.id,
        servers_id: commaSeparatedString,
        password: values.password
      };
      const result = await userServices.updateUser(reqBody);
      if (result.data.status === true) {
        toggle();
        getUsers();
        toast.success("User profile successfully updated", {
          toastId: "update_success",
          autoClose: 3000,
        });
      } else {
        toast.error(result.data.message, {
          toastId: "update_fail",
          autoClose: 3000,
        });
      }
    }
  };

  const filterServerData = (originalArray, valuesToFilterBys) => {
    let arr = [];
    valuesToFilterBys.forEach((element) => {
      arr.push(Number(element));
    });
    const filteredArray = originalArray.filter((item) =>
      arr.includes(item.value)
    );
    return filteredArray;
  };
  return (
    <React.Fragment>
      <Formik
        initialValues={isAddMode === "Add" ? initialValues : savedValues}
        validationSchema={FieldSchema(
          `${isAddMode === "Add" ? "USER_ADD" : "USER_EDIT"}`
        )}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ setFieldValue, values }) => (
          <Form>
            <ModalHeader toggle={toggle}>
              <div className="card-title">
                <h5 className="text-dark">
                  {isAddMode === "Add" ? "Add User" : "Update User"}
                </h5>
              </div>
            </ModalHeader>
            <ModalBody className="modal-body">
              <div className="row">
                <div className="col-12 mb-3">
                  <label htmlFor="name" className="form-label">
                    Name
                  </label>
                  <Field
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                  />
                  <ErrorMessage name="name" component={TextError} />
                </div>
                <div className="col-12 mb-3">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <Field
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                  />
                  <ErrorMessage name="email" component={TextError} />
                </div>
                {/* {isAddMode === "Add" && ( */}
                  <div className="col-12 mb-3">
                    <label htmlFor="password" className="form-label">
                      Password
                    </label>
                    <Field
                      type="password"
                      className="form-control"
                      id="password"
                      name="password"
                    />
                    <ErrorMessage name="password" component={TextError} />
                  </div>
                {/* )} */}
              </div>

              <div className="col-md-12 mb-3">
                <label htmlFor="server" className="form-label">
                  Server
                </label>
                {isAddMode === "Add" ? (
                  <Field
                    name="servers_id"
                    id="servers_id"
                    placeholder="Multi Select"
                    isMulti={true}
                    component={MultiSelect}
                    options={servers}
                  />
                ) : (
                  <Field
                    name="servers_id"
                    id="servers_id"
                    placeholder="Multi Select"
                    isMulti={true}
                    component={MultiSelect}
                    options={servers}
                    defaultInputValue={filterServerData(
                      servers,
                      values.servers_id
                    )}
                  />
                )}
              </div>
            </ModalBody>
            <ModalFooter>
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                onClick={toggle}
              >
                Close
              </button>
              <button type="submit" className="btn btn-primary btn-sm">
                {isAddMode === "Add" ? `Save` : `Update`}
              </button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default UserForm;
