import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useTable, useSortBy, usePagination, useGlobalFilter } from 'react-table';
import { useNavigate } from 'react-router-dom';
import userServices from '../../services/user.services';

// Define a default UI for filtering
const GlobalFilter = ({ globalFilter, setGlobalFilter, pageSize, setPageSize }) => {
  return (
    <div className='pagination-top d-flex justify-content-between align-items-center'>
      <span>
        <select
          value={pageSize}
          onChange={e => setPageSize(Number(e.target.value))}
        >
          {[10, 20, 30, 40, 50].map(size => (
            <option key={size} value={size}>
              Show {size}
            </option>
          ))}
        </select>
      </span>
      <span>
        Search: {' '}
        <input
          value={globalFilter || ''}
          onChange={e => setGlobalFilter(e.target.value || undefined)}
          placeholder="Type to search..."
        />
      </span>
    </div>
  );
};

const BasicServerStatus = ({ selectedRow }) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [id, setId] = useState(selectedRow?.id || 0);

  const getServers = useCallback(async () => {
    const reqBody = {
      ip: selectedRow.ip,
      api_auth: selectedRow?.api_auth || ''
    };
    let result = await userServices.getBasicServerStatus(reqBody);
    result = result.data.data;
    setData(result);
  }, [selectedRow.ip]);

  useEffect(() => {
    getServers();
  }, [getServers]);

  const columns = useMemo(() => [
    { Header: 'Connections', accessor: 'Connections' },
    { Header: 'OutRate', accessor: 'OutRate' },
    { Header: 'SysInfo.ap', accessor: 'SysInfo.ap' },
    { Header: 'SysInfo.scl', accessor: 'SysInfo.scl' },
    { Header: 'SysInfo.tpms', accessor: 'SysInfo.tpms' },
    { Header: 'SysInfo.fpms', accessor: 'SysInfo.fpms' },
    { Header: 'SysInfo.tsss', accessor: 'SysInfo.tsss' },
    { Header: 'SysInfo.fsss', accessor: 'SysInfo.fsss' },
    { Header: 'RamCacheSize', accessor: 'RamCacheSize' },
    { Header: 'FileCacheSize', accessor: 'FileCacheSize' },
    { Header: 'MaxRamCacheSize', accessor: 'MaxRamCacheSize' },
    { Header: 'MaxFileCacheSize', accessor: 'MaxFileCacheSize' }
  ], []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <div>
      <GlobalFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} setPageSize={setPageSize} pageSize={pageSize} />
      <table id="basicDataTable" className="table table-responsive nowrap" {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th key={column.id} {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span>
                    {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row);
            return (
              <tr key={row.id} {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td key={cell.column.id} {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className='navigation-btn'>
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>
      </div>
    </div>
  );
};

export default BasicServerStatus;
