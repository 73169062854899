import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useTable, useSortBy, usePagination, useGlobalFilter } from 'react-table';
import { useNavigate } from 'react-router-dom';
import userServices from '../../services/user.services';
import { bytesToMB, getAgentName } from '../../helpers/Helper';

// Define a default UI for filtering
const GlobalFilter = ({ globalFilter, setGlobalFilter, pageSize, setPageSize }) => {
  return (
    <div className='pagination-top d-flex justify-content-between align-items-center'>
      <span>
        <select
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </span>

      <span>
        Search: {' '}
        <input
          value={globalFilter || ''}
          onChange={e => {
            setGlobalFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
          }}
          placeholder="Type to search..."
        />
      </span>
    </div>
  );
};

const ServerStatusList = ({ selectedRow }) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [id, setId] = useState(selectedRow?.id || 0);

  const getServers = useCallback(async () => {
    const reqBody = {
      ip: selectedRow.ip,
      // api_auth: "ajdjas465SdFSDfdase"?
      api_auth: selectedRow?.api_auth || ''
    };
    let result = await userServices.getServerSession(reqBody);
    result = result.data.data;
    for(let i of result){
      let createdDate = new Date(Number(i.created) * 1000);
      let formattedCreatedDate = createdDate.toISOString().slice(0, 19).replace('T', ' ');

      let last_accessDate = new Date(Number(i.created) * 1000);
      let formatted_last_accessDate = last_accessDate.toISOString().slice(0, 19).replace('T', ' ');

      i.created = formattedCreatedDate
      i.last_access = formatted_last_accessDate
      i.user_agent = getAgentName(i.user_agent)
      i.bytes_recv =` ${bytesToMB(i.bytes_recv)} MB` 
      i.bytes_sent =` ${bytesToMB(i.bytes_sent)} MB` 
    }

    console.log('ressss', result)
    setData(result);
  }, [selectedRow.ip]);

  useEffect(() => {
    getServers();
  }, [getServers]);

  const columns = useMemo(
    () => [
      { Header: 'ID', accessor: 'id', show: false },
      { Header: 'App', accessor: 'app' },
      { Header: 'Stream', accessor: 'stream' },
      { Header: 'Type', accessor: 'type' },
      { Header: 'Created', accessor: 'created' },
      { Header: 'Last Access', accessor: 'last_access' },
      { Header: 'Client Ip', accessor: 'client_ip' },
      { Header: 'User Agent', accessor: 'user_agent' },
      { Header: 'Bytes Recv', accessor: 'bytes_recv' },
      { Header: 'Bytes Sent', accessor: 'bytes_sent' },
    ],
    []
  );


//   {
//     "id": 411356,
//     "app": "ZeeNews",
//     "stream": "ZeeNews",
//     "type": "DASH",
//     "created": 1718094642,
//     "last_access": 1718109573,
//     "client_ip": "103.120.62.96",
//     "user_agent": "UlkaExoPlayer/1.3.7 (Linux;Android 9) ExoPlayerLib/2.13.2",
//     "bytes_recv": 1934601,
//     "bytes_sent": 2088730515
// },

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter, // Use global filter hook
    useSortBy,
    usePagination
  );

  return (
    <div>
      <GlobalFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} setPageSize={setPageSize} pageSize={pageSize} />
      <table id="basicDataTable" className="table table-responsive nowrap" {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th key={column.id} {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span>
                    {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row);
            return (
              <tr key={row.id} {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td key={cell.column.id} {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className='navigation-btn'>
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>
      </div>
    </div>
  );
};

export default ServerStatusList;
