import React, { useEffect, useState } from "react";
import userServices from "../services/user.services";
import { toast } from "react-toastify";
import { Modal, } from "reactstrap";
import UserForm from "../components/Users/UserForm";
import ServersList from "../components/Servers/ServersList";
import ServerForm from "../components/Servers/ServerForm";
import BasicServerStatusList from "../components/Servers/ServerDetailsList";
import ServerDetailsList from "../components/Servers/ServerDetailsList";
import { getLoggedData } from "../helpers/Helper";

const Servers =()=>{

      const [selectedRow, setSelectedRow] = useState();
    const [modal, setModal] = React.useState(false);
    const toggle = () => setModal(!modal);
    const [modalServerList, setModalServerList] = React.useState(false);
    const toggleServerList = () => setModalServerList(!modalServerList);
    const [isAddMode, setIsAddMode] = useState('Add');
    const [servers, setServers] = useState([]);
    const [serverDetailListType, setServerDetailListType]=useState('')
    const loggedData = getLoggedData()
    const getServers = async () => {
        if(loggedData.user.user_type===1){
            let result = await userServices.getServersList();
            result = result.data.data;
            setServers(result);
        }else{
            let result = await userServices.serverListByUser({id: loggedData.user.id});
            result = result.data.data;
            setServers(result);
        }
    }

   
    const handleUserStatus = async (id, status) => {
        let result = await userServices.userStatus({ user_id: id, status: status });
        if (result.data.status === true) {
            getServers();
            toast.success('User status updated successfully', { toastId: 'update_success', autoClose: 3000 });
        } else {
            toast.error(result.data.message, { toastId: 'update_fail', autoClose: 3000 });
        }
    }

    const deleteById = async (id) => {       
        const result = await userServices.deleteUser({ user_id: id });
        if (result.data.status === true) {
            toast.success('User deleted successfully', { toastId: 'userDelete_success', autoClose: 3000 });
            getServers();
        } else {
            toast.error(result.data.message, { toastId: 'userDelete_faild', autoClose: 3000 });
        }
    }

    useEffect(() => {
        getServers();
    }, [])  
    return(
<div className="page-inner">
  {/* Main Wrapper */}
  <div id="main-wrapper">
  
  <div className="pageheader pd-t-25 pd-b-35">
                     <div className="pd-t-5 pd-b-5">
                        <h1 className="pd-0 mg-0 tx-20 text-overflow">Server</h1>
                     </div>
                     {/* <div className="breadcrumb pd-0 mg-0">
                        <a className="breadcrumb-item" href="index.html"><i className="icon ion-ios-home-outline"></i> Home</a>
                        <a className="breadcrumb-item" href="#">Tables</a>
                        <span className="breadcrumb-item active">DataTable Table</span>
                     </div> */}
                  </div>

                  <div className="row row-xs clearfix">
                  <div className="col-md-12 col-lg-12">
                        <div className="card mg-b-20">
                        <div className="card-header">
                              <h4 className="card-header-title">
                                 Server List
                              </h4>
                              <div className="card-header-btn">
                                 <a  href="#" data-toggle="collapse" className="btn card-collapse" data-target="#collapse1" aria-expanded="true"><i className="ion-ios-arrow-down"></i></a>
                                 {/* <a href="#" data-toggle="refresh" className="btn card-refresh"><i className="ion-android-refresh"></i></a>
                                 <a href="#" data-toggle="expand" className="btn card-expand"><i className="ion-android-expand"></i></a>
                                 <a href="#" data-toggle="remove" className="btn card-remove"><i className="ion-android-close"></i></a> */}
                              </div>

                              <div className="btn-group">
                              {loggedData.user?.user_type === 1 && <button className="btn btn-primary btn-sm"
                            onClick={() => {
                                setIsAddMode('Add')
                                toggle()
                            }}>Add</button>}
                        <Modal isOpen={modal} toggle={toggle} size="md">
                            <ServerForm toggle={toggle} isAddMode={isAddMode} selectedRow={selectedRow} getServers={getServers} />
                        </Modal>
                        <Modal isOpen={modalServerList} toggle={toggleServerList} className="modal-dialog-centered" size="lg">
                            <ServerDetailsList toggle={toggleServerList}  serverDetailListType={serverDetailListType} selectedRow={selectedRow}/>
                        </Modal>
                    </div>
                           </div>

                           <div className="card-body collapse show" id="collapse1">
                                <ServersList servers={servers}  setSelectedRow={setSelectedRow}  setModal={setModal} setIsAddMode={setIsAddMode} setServerDetailListType={setServerDetailListType} setModalServerList={setModalServerList}/>
                            </div>
                        </div>
                   </div>     
                </div>
  </div>
  {/*/ Main Wrapper End */}
</div>

    )
}

export default Servers


