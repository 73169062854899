import React, { useEffect, useState } from "react";
import userServices from "../services/user.services";
import ServerStatusList from "../components/Servers/ServerStatusList";
import { useNavigate } from "react-router-dom";
const ServerStatus =()=>{
    const navigate = useNavigate();
      const [selectedRow, setSelectedRow] = useState();
    const [modal, setModal] = React.useState(false);
    const toggle = () => setModal(!modal);
    const [isAddMode, setIsAddMode] = useState('Add');
    const [servers, setServers] = useState([]);

    const getServers = async () => {
        let result = await userServices.getServersList();
        result = result.data.data;
        setServers(result);
    }

    useEffect(() => {
        getServers();
    }, [])  
    return(
<div className="page-inner">
  {/* Main Wrapper */}
  <div id="main-wrapper">
  
  <div className="pageheader pd-t-25 pd-b-35">
                     <div className="pd-t-5 pd-b-5">
                        <h1 className="pd-0 mg-0 tx-20 text-overflow">DataTable Table</h1>
                     </div>
                     {/* <div className="breadcrumb pd-0 mg-0">
                        <a className="breadcrumb-item" href="index.html"><i className="icon ion-ios-home-outline"></i> Home</a>
                        <a className="breadcrumb-item" href="#">Tables</a>
                        <span className="breadcrumb-item active">DataTable Table</span>
                     </div> */}
                  </div>

                  <div className="row row-xs clearfix">
                  <div className="col-md-12 col-lg-12">
                        <div className="card mg-b-20">
                        <div className="card-header">
                              <h4 className="card-header-title">
                                 Basic dataTable
                              </h4>
                              <div className="card-header-btn">
                                 <a  href="#" data-toggle="collapse" className="btn card-collapse" data-target="#collapse1" aria-expanded="true"><i className="ion-ios-arrow-down"></i></a>
                                 {/* <a href="#" data-toggle="refresh" className="btn card-refresh"><i className="ion-android-refresh"></i></a>
                                 <a href="#" data-toggle="expand" className="btn card-expand"><i className="ion-android-expand"></i></a>
                                 <a href="#" data-toggle="remove" className="btn card-remove"><i className="ion-android-close"></i></a> */}
                                 
                              </div>

                              <div className="btn-group">
                              <button className="btn btn-custom-primary"
                            onClick={() => {
                                navigate("/servers");
                            }}>Return To List</button>
                    </div>
                           </div>

                           <div className="card-body collapse show" id="collapse1">
                                <ServerStatusList />
                            </div>
                        </div>
                   </div>     
                </div>
  </div>
  {/*/ Main Wrapper End */}
</div>

    )
}

export default ServerStatus


