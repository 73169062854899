import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
// import Error404 from '../pages/Errors/Error404';
import PrivateRoutes from './PrivateRoutes';
import User from '../pages/User';
import Servers from '../pages/Servers';
import ServerStatus from '../pages/ServerStatus';
import Login from '../components/Login';
import Logout from '../components/Authentication/Logout';
import ServerInfo from '../pages/ServerInfo';
import Home from '../pages/Home';
import Test from '../pages/Test';

// import Logout from '../pages/Authentication/Logout';

// import Error404 from '../pages/Error/Error404';


const AppRoute = () => {
//   const [servers, setServers] = useState([])
//   const getServers = async () => {
//   let result = await userServices.getServersList();
//   console.log('resultRoute', result)
//   result = result.data.data;
//   setServers(result);

// }
// useEffect(()=>{
//   getServers()
// },[])
    return (
        <Router>
            <Routes>
              <Route element={<PrivateRoutes/>}>
                  <Route path='/' element={<User />} />
                  <Route path='/servers' element={<Servers />} />    
                  <Route path='/server_status' element={<ServerStatus />} />    
                  <Route path='/server_info' element={<ServerInfo />} />   
                  {/* <Route path='/test/:id' element={<Test />} /> */}
                  <Route path='/home' element={<Home />} /> 
                  <Route path='/logout' element={<Logout />} />

                  
              </Route>
              <Route path='/login' element={<Login />}/>
              {/* <Route path="*" element={<Error404 />} /> */}
            </Routes>
        </Router>
      );
    }
  export default AppRoute;
