import React, { useEffect, useState } from "react";
import userServices from "../services/user.services";
import { toast } from "react-toastify";
import UserList from "../components/Users/UserList";
import { Modal } from "reactstrap";
import UserForm from "../components/Users/UserForm";

const User = () => {
  const [selectedRow, setSelectedRow] = useState();
  const [modal, setModal] = React.useState(false);
  const toggle = () => setModal(!modal);
  const [isAddMode, setIsAddMode] = useState("Add");
  const [users, setUsers] = useState([]);
  const [servers, setServers] = useState([]);

  const getUsers = async () => {
    let result = await userServices.getAllUsers();
    result = result.data.user;
    setUsers(result);
  };

  const handleUserStatus = async (id, status) => {
    let result = await userServices.userStatus({ user_id: id, status: status });
    if (result.data.status === true) {
      getUsers();
      toast.success("User status updated successfully", {
        toastId: "update_success",
        autoClose: 3000,
      });
    } else {
      toast.error(result.data.message, {
        toastId: "update_fail",
        autoClose: 3000,
      });
    }
  };

  const deleteById = async (id) => {
    const result = await userServices.deleteUser({ user_id: id });
    if (result.data.status === true) {
      toast.success("User deleted successfully", {
        toastId: "userDelete_success",
        autoClose: 3000,
      });
      getUsers();
    } else {
      toast.error(result.data.message, {
        toastId: "userDelete_faild",
        autoClose: 3000,
      });
    }
  };

  const getServers = async () => {
    let result = await userServices.getServersList();
    let serverData = [];
    if (result.status === 200) {
      result.data.data.forEach((element) => {
        serverData.push({ value: element.id, label: element.ip });
      });
      setServers(serverData);
    }
  };

  useEffect(() => {
    getUsers();
    getServers();
  }, []);
  return (
    <div className="page-inner">
      {/* Main Wrapper */}
      <div id="main-wrapper">
        <div className="pageheader pd-t-25 pd-b-35">
          <div className="pd-t-5 pd-b-5">
            <h1 className="pd-0 mg-0 tx-20 text-overflow">Users</h1>
          </div>
          {/* <div className="breadcrumb pd-0 mg-0">
                        <a className="breadcrumb-item" href="index.html"><i className="icon ion-ios-home-outline"></i> Home</a>
                        <a className="breadcrumb-item" href="#">Tables</a>
                        <span className="breadcrumb-item active">DataTable Table</span>
                     </div> */}
        </div>

        <div className="row row-xs clearfix">
          <div className="col-md-12 col-lg-12">
            <div className="card mg-b-20">
              <div className="card-header">
                <h4 className="card-header-title">Users List</h4>
                <div className="card-header-btn">
                  <a
                    href="#"
                    data-toggle="collapse"
                    className="btn card-collapse"
                    data-target="#collapse1"
                    aria-expanded="true"
                  >
                    <i className="ion-ios-arrow-down"></i>
                  </a>
                  {/* <a href="#" data-toggle="refresh" className="btn card-refresh"><i className="ion-android-refresh"></i></a>
                                 <a href="#" data-toggle="expand" className="btn card-expand"><i className="ion-android-expand"></i></a>
                                 <a href="#" data-toggle="remove" className="btn card-remove"><i className="ion-android-close"></i></a> */}
                </div>

                <div className="btn-group">
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() => {
                      toggle();
                      setIsAddMode("Add");
                    }}
                  >
                    Add
                  </button>
                  <Modal isOpen={modal} toggle={toggle} size="md">
                    <UserForm
                      toggle={toggle}
                      isAddMode={isAddMode}
                      selectedRow={selectedRow}
                      getUsers={getUsers}
                      servers={servers}
                    />
                  </Modal>
                </div>
              </div>

              <div className="card-body collapse show" id="collapse1">
                <UserList
                  users={users}
                  setSelectedRow={setSelectedRow}
                  setModal={setModal}
                  setIsAddMode={setIsAddMode}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*/ Main Wrapper End */}
    </div>
  );
};

export default User;
