import { required, yup } from "./CommonValidation";
import * as Yup from 'yup';
export const FieldSchema = (type) => {
    if  (type === 'CHANGE_PASSWORD') {
        return yup({
            old_password: required('Old Password is required'),
            new_password: required('New Password is required'),
            new_confirm_password: required('').oneOf([Yup.ref('new_password'), null], 'New Passwords must match')
        })
    } else if (type === 'USER_ADD') {
        return yup({
            name: required('Please enter User name'),
            email: required('Please enter email id').email('Please enter valid email'),
            password: required('Password is required'),
        })
    } else if (type === 'USER_EDIT') {
        return yup({
            name: required('Please enter User name'),
            email: required('Please enter email id').email('Please enter valid email'),
        })
    } else if (type === 'ADD_SERVER') {
        return yup({
            ip: required('Please enter Server IP'),
            server_name: required('Please Enter Server Name')
        })
    }  
};