
import React from 'react';
import { ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { bytesToMB, filterAgentName, getAgentName } from '../../helpers/Helper';

const ActiveSessionsPopUp = ({toggle, sessionServerList, selectedRow}) => {
    let data = sessionServerList.filter((item) => item.stream ===  selectedRow)
    return (
        <React.Fragment>
     
                    <ModalHeader toggle={toggle}>
                        <div className="card-title">
                            <h5 className='text-dark'>Active Session: {selectedRow}</h5>
                        </div>    
                    </ModalHeader>
                    <ModalBody className="modal-body">
                    <table  className="table">
                        <thead>
                          <tr>
                            <th scope="col">Session Id</th>
                            <th scope="col">Channel Name</th>
                            {/* <th scope="col">Client IP</th> */}
                            <th scope="col">Bytes Rec</th>
                            <th scope="col">Bytes Sent</th>
                            <th scope="col">User Agent</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.map((item, index) => (
                              <tr key={index}>
                                <td>{item.id}</td>
                                <td >{item.stream}</td>
                                {/* <td>{item.client_ip}</td> */}
                                <td>{bytesToMB(item.bytes_recv)} MB</td>
                                <td>{bytesToMB(item.bytes_sent)} MB</td>
                                {/* <td>{filterAgentName(item.user_agent)}</td> */}
                                <td>{getAgentName(item.user_agent)}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </ModalBody>
                    <ModalFooter>
                        <button
                            type="button"
                            className="btn btn-secondary btn-sm"
                            onClick={toggle}
                        >
                            Close
                        </button>
                    </ModalFooter>
              
        </React.Fragment>
    );
};

export default ActiveSessionsPopUp;