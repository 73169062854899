import React from 'react';
import { Navigate } from 'react-router-dom';  
import Layout from '../components/Layout/Layout';
import { getLoggedData } from '../helpers/Helper';

const PrivateRoutes = () => {
  const getUserData = getLoggedData();
  return (
    getUserData?.auth === true ? <><Layout /></> : <Navigate to='/login' />
  )
}
export default PrivateRoutes;