import React, { useEffect, useState } from "react";
import userServices from "../services/user.services";
import { getLoggedData } from "../helpers/Helper";
import { useLocation, useParams } from "react-router-dom";

const Home = () => {
  const { id } = useParams();
  const location = useLocation();
  const [servers, setServers] = useState([]);
  const loggedData = getLoggedData();
  const getServers = async () => {
    if (loggedData.user.user_type === 1) {
      let result = await userServices.getServersList();
      result = result.data.data;
      setServers(result);
    } else {
      let result = await userServices.serverListByUser({
        id: loggedData.user.id,
      });
      result = result.data.data;
      setServers(result);
    }
  };



  useEffect(() => {
    getServers();
  },  [location.pathname, id]);
  return (
    <div className="page-inner">
      {/* Main Wrapper */}
      <div id="main-wrapper">
        <div className="pageheader pd-t-25 pd-b-35">
          <div className="pd-t-5 pd-b-5">
            <h1 className="pd-0 mg-0 text-overflow">Home</h1>
          </div>
        </div>

        <div className="row row-xs clearfix">
  <div className="col-sm-6 col-xl-3">
    <div className="card mg-b-20">
      <div className="card-body pd-y-0">
        <div className="custom-fieldset mb-4">
          <div className="clearfix">
            <label>Today Servers</label>
          </div>
          <div className="d-flex align-items-center text-dark">
            <div className="wd-40 wd-md-50 ht-40 ht-md-50 mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded card-icon-warning">
              <i className="icon-screen-desktop tx-warning tx-20" />
            </div>
            <div>
              <h2 className="tx-20 tx-sm-18 tx-md-24 mb-0 mt-2 mt-sm-0 tx-normal tx-rubik tx-dark">
                <span className="counter">{servers.length}</span>
                
              </h2>
        
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

      </div>
      {/*/ Main Wrapper End */}
    </div>
  );
};

export default Home;
