import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react';
import { ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { toast } from 'react-toastify';
import { FieldSchema } from '../../schema/FieldSchema';
import TextError from '../../helpers/FormikHelper/TextError';
import userServices from '../../services/user.services';

const ServerForm = ({ toggle, isAddMode, selectedRow, getServers }) => {

    const initialValues = {
        ip: '',
        api_auth: '',
        server_name: ''
    }

    const savedValues = {
        ip: selectedRow?.ip,
        api_auth: selectedRow?.api_auth,
        server_name: selectedRow?.server_name
    }

    const onSubmit = async (values) => {
        const reqBody = {
            ip: values.ip,
            api_auth: values.api_auth,
            server_name: values.server_name,
        }
        if (isAddMode === 'Add') {
            let result = await userServices.addServer(reqBody);
            if (result.data.status === true) {
                toggle()
                getServers()
                toast.success('Add User successfully', { toastId: 'registration_success', autoClose: 3000 });
            } else {
                toast.error(result.data.message, { toastId: 'registration_fail', autoClose: 3000 });
            }
        } else {
            const reqBody = {
                ip: values.ip,
                api_auth: values.api_auth,
                server_name: values.server_name,
                id: selectedRow?.id     
            }                 
            const result = await userServices.updateServer(reqBody);
            if (result.data.status === true) {
                toggle()
                getServers()
                toast.success('Server Detail successfully updated', { toastId: 'update_success', autoClose: 3000 });
            } else {
                toast.error(result.data.message, { toastId: 'update_fail', autoClose: 3000 });

            }
        }       
    }

    return (
        <React.Fragment>
            <Formik
                initialValues={isAddMode === 'Add' ? initialValues : savedValues}
                validationSchema={FieldSchema(`${isAddMode === 'Add' ? 'ADD_SERVER' : 'ADD_SERVER'}` )}
                onSubmit={onSubmit}
                enableReinitialize
            >
                {({ setFieldValue, values }) => (<Form>
                    <ModalHeader toggle={toggle}>
                        <div className="card-title">
                            <h5 className='text-dark'>{isAddMode === 'Add' ? 'Add Server' : 'Update Server'}</h5>
                        </div>    
                    </ModalHeader>
                    <ModalBody className="modal-body">
                        <div className="row" >
                        <div className="col-12 mb-3">
                                <label htmlFor="server_name" className="form-label">
                                    Server Name
                                </label>
                                <Field type="text" className="form-control" id="server_name" name='server_name' />
                                <ErrorMessage name='server_name' component={TextError} />
                            </div>
                            <div className="col-12 mb-3">
                                <label htmlFor="ip" className="form-label">
                                    IP
                                </label>
                                <Field type="text" className="form-control" id="ip" name='ip' />
                                <ErrorMessage name='ip' component={TextError} />
                            </div>
                            <div className="col-12 mb-3">
                                <label htmlFor="api_auth" className="form-label">
                                    API Authentication
                                </label>
                                <Field type="api_auth" className="form-control" id="api_auth" name='api_auth'
                                />
                                <ErrorMessage name='api_auth' component={TextError} />
                            </div>
                           </div>
                    </ModalBody>
                    <ModalFooter>
                        <button
                            type="button"
                            className="btn btn-secondary btn-sm"
                            onClick={toggle}
                        >
                            Close
                        </button>
                        <button type="submit" className="btn btn-primary btn-sm">
                            {isAddMode === "Add" ? `Save` : `Update`}
                        </button>
                    </ModalFooter>
                </Form>
                )}
            </Formik>
        </React.Fragment>
    );
};

export default ServerForm;